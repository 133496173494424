<template>
  <el-select
    v-model="valueModel"
    :multiple="multiple"
    filterable
    remote
    reserve-keyword
    placeholder="请输入应用名称"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="handleChange"
    style="width: 100%"
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      <service-name :name="item.label" />
    </el-option>
  </el-select>
</template>
<script>
import { ref, watch } from 'vue';
import { getApps, getAppsMain } from '@/api/app';
export default {
  name: 'AppsSelector',
  props: {
    type: {
      type: String,
      default: 'Main', // 'Main|Micro'
    },
    value: {
      type: Number,
      default: null,
    },
    env: {
      type: String,
      default: 'PROD',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    handlerFilter: {
      type: Function,
      default() {
        return (rows) => rows !== false;
      },
    },
  },
  setup(props, { emit }) {
    const options = ref([]);
    const valueModel = ref(props.value);
    const loading = ref(false);
    watch(
      () => props.value,
      (val) => (valueModel.value = val),
    );
    const fetchList = async (keyword) => {
      const api = props.type === 'Main' ? getAppsMain : getApps;
      try {
        const {
          data: { rows },
        } = await api({
          keyword,
          pageSize: 1000,
          env: props.env,
        });
        return rows;
      } catch (e) {
        console.log(e);
        return [];
      }
    };

    const remoteMethod = async (query) => {
      loading.value = true;
      const list = await fetchList(query);
      options.value = list
        .map((item) => ({
          label: item.name,
          value: item.id,
          projectId: item.projectId,
        }))
        .filter((row) => props.handlerFilter(row));
      loading.value = false;
    };

    remoteMethod();

    const handleChange = (v) => {
      emit('change', v);
    };

    const getSelectedAppInfo = () => {
      const app = options.value.find((item) => item.value === valueModel.value);

      return app;
    };

    return {
      valueModel,
      options,
      loading,
      remoteMethod,
      handleChange,
      getSelectedAppInfo,
    };
  },
};
</script>
