<template>
  <ul class="app-items">
    <li class="app-item" v-for="item in list" :key="item.id">
      <div class="app-item__body" @click="handleViewApp(item)">
        <figure class="app-item__thumb">
          <img :src="item.thumbnail || noImage" :alt="item.name" />
        </figure>
        <div class="app-item__content">
          <h3 class="app-item__title" :title="item.name"><service-name :name="item.name" /></h3>
          <p class="app-item__desc" :title="item.desc">{{ item.desc }}</p>
          <div class="app-item__actions">
            <a class="action-item" @click.stop="handleRemove(item)" v-if="getShowBool('delete')">
              <i class="el-icon-delete"></i>
              删除
            </a>
            <!-- <a class="action-item">升级</a> -->
            <a
              class="action-item"
              @click.stop="handleEdit(item)"
              v-if="Number(item.type) === 2 && getShowBool('update')"
            >
              <i class="el-icon-edit"></i>
              编辑信息
            </a>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { defineComponent } from 'vue';
import { confirmMessageBox } from '@/utils/confirm';
import noImage from '@/assets/img/no-img.svg';
import { ElMessage } from 'element-plus';
import { delAppCenterApp } from '@/api/repository';
import { getShowBool } from '@/utils/permission-show-module';
export default defineComponent({
  name: 'AppList',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const removeItem = async (item) => await delAppCenterApp(item.id);

    const handleRemove = async (item) => {
      try {
        await confirmMessageBox(
          '只是从应用管理中心移除应用登记记录，并不是从运行环境删除应用。',
          '确认删除应用',
          removeItem,
          item,
        );
        emit('change');
        ElMessage.success('删除成功');
      } catch (e) {
        console.log(e);
      }
    };

    const handleEdit = (item) => {
      emit('edit', item);
    };

    const handleViewApp = (item) => {
      if (!item.link) {
        ElMessage.error('该应用未配置地址');
        return;
      }
      window.open(item.link);
    };

    return {
      noImage,
      getShowBool,
      handleRemove,
      handleEdit,
      handleViewApp,
    };
  },
});
</script>
<style scoped lang="scss">
.app-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, 20%);
  margin: -10px -10px 10px -10px;
  padding: 0;
  list-style: none;
  font-size: 0;
}
@media screen and (max-width: 1800px) {
  .app-items {
    grid-template-columns: repeat(auto-fill, 25%);
  }
}
@media screen and (max-width: 1400px) {
  .app-items {
    grid-template-columns: repeat(auto-fill, 33.33%);
  }
}
@media screen and (max-width: 1200px) {
  .app-items {
    grid-template-columns: repeat(auto-fill, 50%);
  }
}
@media screen and (max-width: 900px) {
  .app-items {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}
.app-item {
  padding: 10px;
  &__body {
    background-color: white;
    padding: 15px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    }
  }
  &__thumb {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  &__content {
    flex: 1;
    margin-left: 20px;
    font-size: 12px;
    overflow: hidden;
  }
  &__title {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__desc {
    margin: 15px 0 0 0;
    white-space: normal;
    word-break: break-all;
    line-height: 18px;
    height: 36px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 -5px -10px;
    .action-item {
      display: inline-flex;
      align-items: center;
      padding: 10px;
      &:hover,
      &:active {
        background-color: #f8f8f8;
        transition: all 0.2s;
      }
      i {
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
}
</style>
