<template>
  <div class="sa-list">
    <aside class="sa-list__head">
      <div class="sa-list__head_item">
        <el-button type="primary" @click="handleCreate" v-if="getShowBool('add')">添加应用</el-button>
      </div>
      <div class="sa-list__head_item">
        <!-- <el-radio-group v-model="query.status" @change="handleSearch">
          <el-radio-button :label="0">全部</el-radio-button>
          <el-radio-button :label="1">进行中</el-radio-button>
          <el-radio-button :label="2">待审核</el-radio-button>
        </el-radio-group> -->
        <el-input
          class="sa-list__head_search"
          placeholder="请输入"
          v-model="query.keyword"
          suffix-icon="el-icon-search"
          @keyup.enter="handleSearch"
        ></el-input>
      </div>
    </aside>
    <main class="sa-list__main">
      <list-wrap
        backgroundColor="transparent"
        :loading="loading"
        :empty="list.length === 0"
        :handleCreate="handleCreate"
        :hasCreateAuth="getShowBool('add')"
      >
        <app-list :list="list" @change="fetchList" @edit="handleEdit" />
        <packaged-pagination
          v-if="total && !loading"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="total"
          layout="prev, pager, next"
          @size-change="handlePageSizeChange"
          @current-change="handlePageChange"
        ></packaged-pagination>
      </list-wrap>
    </main>
  </div>
  <create-dialog ref="refCreateDialog" @success="handleSearch" />
</template>
<script>
import { ref } from 'vue';
import { getShowBool } from '@/utils/permission-show-module';
import AppList from './Items.vue';
import CreateDialog from './create/Dialog.vue';
import { getAppCenterAppList } from '@/api/app';
import { useSaList } from '@/shared/hooks/list';
export default {
  components: {
    AppList,
    CreateDialog,
  },
  setup() {
    const { list, loading, total, query, handleSearch, handlePageChange, handlePageSizeChange, fetchList } = useSaList(
      async (query) => {
        loading.value = true;
        try {
          const {
            data: { rows, count },
          } = await getAppCenterAppList(query);
          list.value = rows;
          total.value = Number(count);
        } catch (e) {
        } finally {
          loading.value = false;
        }
      },
      {
        pageSize: 20,
      },
    );
    fetchList();

    const refCreateDialog = ref();

    const handleCreate = () => {
      refCreateDialog.value.handleOpenDialog();
    };

    const handleEdit = (item) => {
      refCreateDialog.value.handleOpenDialog(item);
    };

    return {
      query,
      loading,
      list,
      total,
      handleSearch,
      handleCreate,
      handleEdit,
      handlePageSizeChange,
      handlePageChange,
      getShowBool,
      fetchList,
      refCreateDialog,
    };
  },
};
</script>
