<template>
  <el-dialog
    custom-class="create-form-dialog"
    v-model="visible"
    :title="`${form.id ? '编辑' : '添加'}应用系统`"
    width="520px"
    @close="handleCloseDialog"
    destroy-on-close
  >
    <el-form ref="refForm" :rules="rules" :model="form" label-width="120px">
      <el-form-item label="应用来源" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">内部应用</el-radio>
          <el-radio :label="2">外部应用</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="form.type === 1">
        <el-form-item label="应用系统" prop="appId">
          <apps-selector v-model="form.appId" ref="refAppSelector" :handlerFilter="handlerFilterInnerApps" />
        </el-form-item>
      </template>
      <template v-else>
        <el-form-item label="应用名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入" maxlength="100"></el-input>
        </el-form-item>
        <el-form-item label="URL" prop="link">
          <el-input v-model="form.link" placeholder="http://" maxlength="255"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="thumbnail">
          <el-upload
            class="avatar-uploader"
            :action="IMAGE_UPLOAD"
            accept=".jpg,.png,.jpeg"
            :show-file-list="false"
            :before-upload="handleBeforeUpload"
            @success="handleUploaded"
            @error="handleUploadError"
          >
            <el-button :loading="uploading">{{ uploading ? '上传中..' : '上传文件' }}</el-button>
            <span style="margin-left: 20px" class="sa-text-success">{{ form.thumbnail ? '已上传' : '' }}</span>
          </el-upload>
          <p class="tip">支持扩展名：.jpg .png .jpeg， 建议大小为48px * 48px，支持最大上传3M文件</p>
        </el-form-item>
        <el-form-item label="应用描述" prop="desc">
          <el-input
            v-model="form.desc"
            maxlength="1024"
            placeholder="请输入至少10个字符"
            show-word-limit
            type="textarea"
          />
        </el-form-item>
      </template>
    </el-form>
    <div class="dialog-foot">
      <el-button type="primary" @click="handleSubmit" :loading="submitting">确定</el-button>
      <el-button @click="handleCloseDialog">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { defineComponent, reactive, ref } from 'vue';
import { IMAGE_UPLOAD } from '@/shared/constant/file';
import { saveAppCenterInnerApp, saveAppCenterOuterApp, getInnerApps } from '@/api/repository';
import { ElMessage } from 'element-plus';
import AppsSelector from './AppsSelector.vue';
import { getAppDetailById } from '@/api/app';
export default defineComponent({
  name: 'CreateDialog',
  components: {
    AppsSelector,
  },
  setup(props, { emit }) {
    const visible = ref(false);
    const form = reactive({
      type: 2,
      name: '',
      link: '',
      thumbnail: '',
      desc: '',
      appId: '',
    });
    const refForm = ref();
    const submitting = ref(false);
    const uploading = ref(false);
    const refAppSelector = ref();
    const rules = {
      name: [
        {
          required: true,
          message: '请输入应用名称',
          trigger: 'blur',
        },
      ],
      appId: [
        {
          required: true,
          message: '请选择应用',
          trigger: 'blur',
        },
      ],
      link: [
        {
          required: true,
          message: '请输入URL',
          trigger: 'blur',
        },
        {
          type: 'url',
          message: '必须是一个URL',
          trigger: 'blur',
        },
      ],
      desc: [
        {
          required: true,
          message: '请输入应用描述',
          trigger: 'blur',
        },
        {
          min: 10,
          message: '至少输入10个字符',
          trigger: 'blur',
        },
      ],
    };

    const innerApps = ref([]);
    const fetchInnerApps = async () => {
      try {
        const { data } = await getInnerApps();
        innerApps.value = data;
      } catch (e) {}
    };

    const handlerFilterInnerApps = (row) => !innerApps.value.find((item) => Number(row.value) === Number(item));

    const fetchInnerAppDetail = async (appId, projectId) => {
      try {
        const { data } = await getAppDetailById(appId, {
          projectId,
        });
        return data;
      } catch (e) {
        submitting.value = false;
        return {};
      }
    };

    const handleOpenDialog = async (source = null) => {
      fetchInnerApps();
      visible.value = true;
      if (source) {
        Object.keys(source).forEach((key) => (form[key] = source[key]));
        form.type = Number(form.type);
        if (form.thumbnail) {
          try {
            const thumbnail = new URL(form.thumbnail);
            form.thumbnail = thumbnail.pathname;
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        form.type = 2;
        form.name = '';
        form.link = '';
        form.thumbnail = '';
        form.desc = '';
        form.appId = '';
      }
    };
    const handleCloseDialog = () => {
      visible.value = false;
      submitting.value = false;
      refForm.value.resetFields();
      form.id = undefined;
    };
    const handleSubmit = async () => {
      const valid = await refForm.value.validate();
      if (!valid) {
        return;
      }
      submitting.value = true;
      const api = form.type === 1 ? saveAppCenterInnerApp : saveAppCenterOuterApp;
      if (form.type === 1) {
        const selectedAppInfo = refAppSelector.value.getSelectedAppInfo();
        const app = await fetchInnerAppDetail(form.appId, selectedAppInfo.projectId);
        form.name = app.description;
        form.desc = app.remark;
        const { host, origin } = location;
        const tenetName = host.split('.')[0];
        form.link = origin.replace(tenetName, `${app.previewUrn.replace(/\./g, '-')}.preview`);
        if (app.thumbnail) {
          try {
            const thumbnail = new URL(app.thumbnail);
            form.thumbnail = thumbnail.pathname;
          } catch (e) {
            form.thumbnail = '';
          }
        }
      }
      try {
        await api(form);
        ElMessage.success('保存成功');
        submitting.value = false;
        emit('success');
        handleCloseDialog();
      } catch (e) {
        console.log(e);
        submitting.value = false;
      }
    };
    const handleBeforeUpload = (file) => {
      if (file.size > 1024 ** 2 * 3) {
        ElMessage.warning('上传图片大小不能超过3M');
        return false;
      }
      uploading.value = true;
    };
    const handleUploaded = (res) => {
      if (res.code === 0 && res.data?.fileKey) {
        form.thumbnail = res.data.fileKey;
        ElMessage.success('上传成功');
      } else {
        ElMessage.error('上传失败，请重新上传！');
      }
      uploading.value = false;
    };
    const handleUploadError = () => {
      uploading.value = false;
      ElMessage.error('上传失败，请重新上传！');
    };
    return {
      visible,
      form,
      refForm,
      uploading,
      submitting,
      IMAGE_UPLOAD,
      rules,
      refAppSelector,
      handleOpenDialog,
      handleCloseDialog,
      handleSubmit,
      handleBeforeUpload,
      handleUploaded,
      handleUploadError,
      handlerFilterInnerApps,
    };
  },
});
</script>
<style lang="scss" scoped>
.dialog-foot {
  text-align: center;
}
.tip {
  margin: 5px 0 0 0;
  color: #999;
  line-height: 20px;
}
</style>
