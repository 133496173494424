/* eslint-disable no-param-reassign */
import { ElMessageBox } from 'element-plus';

/**
 * 统一二次封装Confirm提示框
 * @param content 提示内容
 * @param title 提示标题
 * @param handler confirm执行函数
 * @param args confirm函数参数
 * @returns
 */
export const confirmMessageBox = async (content: string, title = '确认删除', handler: Function, ...args: any[]) =>
  new Promise((resolve, reject) => {
    ElMessageBox.confirm(content, title, {
      confirmButtonText: '确定删除',
      cancelButtonText: '取消',
      type: 'warning',
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          try {
            const res = await handler(...args);
            done();
            resolve(res);
          } catch (e) {
            console.log(e);
            reject(e);
          }
          instance.confirmButtonLoading = false;
        } else {
          done();
        }
      },
    });
  });
